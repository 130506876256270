<template>
  <div style="font-size: 1.1em">
    <TrueOrFalse
      v-if="question.questionType === 0"
      :subject="clearWord(question.stem)"
      :show-answer="showAnswer"
      :user-answer="question.userAnswer"
      :score="question.userScore"
      :show-score="showScore"
      :answer="Boolean(question.answer)"
    />
    <SingleChoice
      v-if="question.questionType === 1 || question.questionType === 2"
      :subject="clearWord(question.stem)"
      :user-answer="question.userAnswer"
      :score="question.userScore"
      :is-multiple="question.questionType === 2"
      :options="question.options"
      :show-answer="showAnswer"
      :show-score="showScore"
    />
    <FillBlank
      v-if="question.questionType === 3"
      :subject="clearWord(question.stem)"
      :show-answer="showAnswer"
      :user-answer="question.userAnswer"
      :score="question.userScore"
      :correct="question.correct"
      :show-score="showScore"
      :blanks="question.blanks"
    />
    <Subjective
      v-if="question.questionType === 4"
      :subject="clearWord(question.stem)"
      :show-answer="showAnswer"
      :user-answer="question.userAnswer"
      :score="question.userScore"
      :show-score="showScore"
    />
    <Code
      v-if="question.questionType === 5"
      :subject="clearWord(question.stem)"
      :user-answer="question.userAnswer"
      :score="question.userScore"
      :show-score="showScore"
    />
    <div class="analysis" v-if="showAnswer">
      <div class="analysis-label">解析：</div>
      <div v-html="question.analysis"></div>
    </div>
  </div>
</template>

<script>
import SingleChoice from './SingleChoice.vue'
import FillBlank from './FillBlank.vue'
import TrueOrFalse from './TrueOrFalse.vue'
import Subjective from './Subjective.vue'
import Code from './Code'
/**
 * 0: True Or False
 * 1: Single Choice
 * 2: Multi Choice
 * 3: Fill Blank
 * 4: Subjective
 */
export default {
  props: {
    question: {
      required: true,
      type: Object
    },
    showAnswer: {
      type: Boolean,
      default: false
    },
    showScore: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SingleChoice,
    FillBlank,
    TrueOrFalse,
    Subjective,
    Code
  },
  methods: {
    clearWord(input) {
      let stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g
      let output = input.replace(stringStripper, ' ')
      // 2. strip Word generated HTML comments
      let commentSripper = new RegExp('<!--(.*?)-->', 'g')
      output = output.replace(commentSripper, '')
      let tagStripper = new RegExp('<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>', 'gi')
      // 3. remove tags leave content if any
      output = output.replace(tagStripper, '')
      // 4. Remove everything in between and including tags '<style(.)style(.)>'
      let badTags = ['style', 'script', 'applet', 'embed', 'noframes', 'noscript']

      for (let i = 0; i < badTags.length; i++) {
        tagStripper = new RegExp('<' + badTags[i] + '.*?' + badTags[i] + '(.*?)>', 'gi')
        output = output.replace(tagStripper, '')
      }
      // 5. remove attributes ' style="..."'
      let badAttributes = ['style', 'start']
      for (let i = 0; i < badAttributes.length; i++) {
        let attributeStripper = new RegExp(' ' + badAttributes[i] + '="(.*?)"', 'gi')
        output = output.replace(attributeStripper, '')
      }
      output = output.replace(/<br \/>/, '')
      return output
    }
  }
}
</script>

<style lang="less" scoped>
.analysis {
  display: flex;
  margin-top: 10px;

  .analysis-label {
    flex: 0 0 auto;
  }
}
</style>

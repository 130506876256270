<template>
  <div>
    <div class="quesStyle" style="display: flex;margin: 20px 0;max-width: 300px;flex-direction: column" v-html="subject"></div>
    <div v-if="!showAnswer" class="options-container question-image-size-restrict">
      <div v-for="(opt, index) in options" :key="index + '-only'">
        {{ getChoiceLetter(index) }}.
        <span v-html="opt.content.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')" />
      </div>
    </div>
    <div v-else class="options-container question-image-size-restrict">
      <Radio v-for="(option, index) in options" :key="option.content" :value="correctAnswer.includes(index)">
        <span
          >{{ getChoiceLetter(index) }}、
          <span v-html="option.content.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')" />
        </span>
      </Radio>
    </div>
    <div class="exam-result" v-if="showScore">
      <div class="examinee-answer">
        <span>考生答案：</span>
        <span :class="{ error: !correct, right: correct }">{{ answer }}</span>
      </div>
      <div class="correct-answer">
        <span>正确答案：</span>
        <span>{{ correctAnswer.map(v => getChoiceLetter(v)).join('') }}</span>
      </div>
      <div class="score">
        <span>得分：</span>
        <span>{{ score }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    subject: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    showAnswer: {
      type: Boolean,
      default: false
    },
    showScore: {
      type: Boolean,
      default: false
    },
    score: {
      type: Number,
      default: 0
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    userAnswer: {
      required: false
    }
  },
  methods: {
    getChoiceLetter(index) {
      return String.fromCharCode(index + 65)
    }
  },
  computed: {
    correctAnswer() {
      const answers = []
      this.options.forEach((option, index) => {
        if (option.correct) {
          answers.push(index)
        }
      })
      answers.sort()
      return answers
    },
    answerIndexes() {
      if (!this.userAnswer) {
        return '无'
      }

      const indexes = []
      this.options.forEach((option, index) => {
        if (this.isMultiple && this.userAnswer.includes(option.content)) {
          indexes.push(index)
        } else if (!this.isMultiple && this.userAnswer === option.content) {
          indexes.push(index)
        }
      })
      indexes.sort()
      return indexes
    },
    answer() {
      return Array.isArray(this.answerIndexes) ? this.answerIndexes.map(index => this.getChoiceLetter(index)).join('') : this.answerIndexes
    },
    correct() {
      if (!Array.isArray(this.answerIndexes)) {
        return false
      }

      return this.correctAnswer.join('') === this.answerIndexes.join('')
    }
  }
}
</script>
<style lang="less" scoped>
.options-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 5px;
  ::v-deep .ivu-radio-wrapper {
    display: block;
    pointer-events: none;
    margin: 10px 0;
  }
}

.exam-result {
  display: flex;
  color: #3c88d3;

  .examinee-answer {
    margin-right: 10px;
  }

  .correct-answer {
    margin-right: 10px;
  }

  .error {
    color: #ed4014;
  }

  .right {
    color: #19be6b;
  }
}
.quesStyle {
  text-align: left;
}
</style>
